@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 100px solid white;
  border-top: 0;
}

.triangle::before {
  content: "";
  position: absolute;
  top: -100px;
  left: -50px;
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 100px solid blue;
}


/* #parent span {
  visibility: hidden;
} */

/* Apply the fade-in animation when the parent is being hovered */
/* #parent:hover span {
  display: inline-block;
  animation: fadeIn 0s 0.2s forwards; /* Delay appearance by 3 seconds */
/* } */

/* Keyframes definition to change visibility */
/* @keyframes fadeIn {
  0% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
} */